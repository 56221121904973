var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"dialog",attrs:{"title":"新增","visible":_vm.visiable},on:{"update:visible":function($event){_vm.visiable=$event}}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"dlalog-body"},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"label-width":"180px"}},[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"测点名称：","prop":"name"}},[_c('el-cascader',{attrs:{"options":_vm.treeData,"props":{
								value: 'id',
								label: 'name',
								children: 'children',
								emitPath: false
							}},model:{value:(_vm.formData.measId),callback:function ($$v) {_vm.$set(_vm.formData, "measId", $$v)},expression:"formData.measId"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"智寰传感器：","prop":"brand"}},[_c('el-cascader',{attrs:{"options":_vm.treeData2,"props":{
								value: 'id',
								label: 'name',
								children: 'children',
								emitPath: false
							}},on:{"change":_vm.handleChange},model:{value:(_vm.formData.sensorId),callback:function ($$v) {_vm.$set(_vm.formData, "sensorId", $$v)},expression:"formData.sensorId"}})],1)],1)],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.close()}}},[_vm._v("关闭")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("确定")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }